import '../../styles/mobile/homepage.css'
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick.js';

((
    ($) => {
        const _dir = $('html').attr('dir');
        $('.list-8-wrapper').slick({
            dots: false,
            infinite: true,
            autoplay: true,
            rtl: (_dir === "rtl"),
            speed: 300,
            autoplaySpeed: 4000,
            slidesToShow: 4,
            centerMode: true,
            slidesToScroll: 1,
            prevArrow: '<i class="icon-arrow-right list-7-arrow right list-8"></i>',
            nextArrow: '<i class="icon-arrow-left list-7-arrow left list-8"></i>',
            responsive: [
                {
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                    }
                },
            ]
        });
        $('.list-7').slick({
            dots: false,
            infinite: false,
            autoplay: true,
            rtl: (_dir === "rtl"),
            speed: 300,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<i class="icon-arrow-right list-7-arrow right"></i>',
            nextArrow: '<i class="icon-arrow-left list-7-arrow left"></i>',
        });

        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };
        let counter = 2;
        let _list = $('#list11');
        let _url = _list.data('url');
        let _pages = _list.data('pages');
        let flag = true;
        let loaded = false;
        let intersectionCallback1 = function (entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    let elem = entry.target;
                    if (entry.intersectionRatio >= 0.1) {
                        if (counter > _pages) {
                            return false;
                        }
                        renderItems();
                    }
                }
            });
        };
        let observer1 = new IntersectionObserver(intersectionCallback1, options);
        let target1 = document.querySelector('#default-footer');
        observer1.observe(target1);

        function renderItems() {
            if (flag === true) {
                $.ajax({
                    url: _url + counter,
                    method: 'GET',
                    beforeSend: function () {
                        flag = false;
                        $('#list-11-loading').fadeIn();
                    },
                    success: function (response) {
                        $('#list11 .list-4').append(response);
                    },
                    complete: function () {
                        $('#list-11-loading').fadeOut();
                        counter++;
                        flag = true;
                    }
                })
            }
        }


        $('.list-7-item').on('click', function (e) {
            const itemIcon = $(this).find('.list-7-item-icon').first();

            let video_frame = null;
            let type = itemIcon.data('type');
            let _video_src = itemIcon.data('src');
            let width = itemIcon.data('width');
            let height = itemIcon.data('height');

            if (type === 'html5') {
                video_frame = `<video class="list-7-item__video" autoplay width="${width}" height="${height}" controls src="${_video_src}" $poster>
    <source src="${_video_src}" type="video/mp4">
    Your browser does not support the video tag.
</video>
`;

            } else {
                video_frame = document.createElement('iframe');
                video_frame.setAttribute('frameborder', '0');
                video_frame.setAttribute('scrolling', 'no');
                video_frame.setAttribute('width', width);
                video_frame.setAttribute('height', height);
                video_frame.setAttribute('class', 'list-7-item__video');
                video_frame.setAttribute('src', _video_src);
                // video_frame.attr('src', _video_src);
                // $(this).append(video_frame);

            }
            $(this).append(video_frame);
            itemIcon.fadeOut();

        });

        $('.list-7-arrow').on('click', function () {
            $('.list-7-item iframe').attr('src', "");
            $('.list-7-item-icon').fadeIn();
        });
    }
)(jQuery));
